<template>
    <div class="side">
        <transition name="fade">
            <div class="left">
                <slot name="left-content"></slot>
            </div>
        </transition>
        <div class="right">
            <slot name="right-content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hasExpand: false,
        }
    },
    props: ['minLeftWidth', 'minRightWidth'],
    mounted() {},
    methods: {
        updateExpand() {
            this.hasExpand = !this.hasExpand
            this.$emit('onExpand', this.hasExpand)
        }
    },
    watch: {

    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";
.side {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    @include background_color(sideLayoutBgColor);
}

.left {
    @include background_color(sideLayoutBgColor);
    overflow-x: auto;
    margin: 10px 0;
    position: relative;
}

.right {
    flex: 1;
    overflow-x: auto;
    @include background_color(sideLayoutBgColor);
}

.float-btn {
    width: 60px;
    height: 60px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    z-index: 9;
    left: 30px;
    bottom: 30px;
    opacity: 0.3;
}
</style>