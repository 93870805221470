var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",attrs:{"id":"animation-panel"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"liftLevel"},_vm._l((_vm.lift.list),function(i){return _c('div',{staticClass:"liftItem"},[(_vm.monitorPageDisplay.floor.value == i)?_c('div',{staticClass:"liftBody"},[_c('div',{staticClass:"frame",class:{ isOpen: _vm.lift.isOpen }},[_c('div',{class:_vm.monitorPageDisplay.doorStatus.value == 2 ||
                _vm.monitorPageDisplay.doorStatus.value == 0 ||
                _vm.monitorPageDisplay.doorStatus.value == 3
                  ? 'door dooronline'
                  : 'door leftDoor'}),_c('div',{class:_vm.monitorPageDisplay.doorStatus.value == 2 ||
                _vm.monitorPageDisplay.doorStatus.value == 0 ||
                _vm.monitorPageDisplay.doorStatus.value == 3
                  ? 'door dooronline'
                  : 'door rightDoor'}),(_vm.monitorPageDisplay.direction.value == 1)?_c('i',{staticClass:"el-icon-caret-top"}):(_vm.monitorPageDisplay.direction.value == 2)?_c('i',{staticClass:"el-icon-caret-bottom"}):_c('i',{staticClass:"el-icon-remove"})])]):_vm._e(),_c('div',{class:i >=_vm.lift.iMinFloor && i <= _vm.lift.iMaxFloor ? 'floor' : 'floor online'},[_vm._v(_vm._s(i))])])}),0),_c('div',{staticClass:"dt-no"},[_vm._v(_vm._s(_vm.elevator.v_elevator_name))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }